<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <img
            src="@/assets/images/logo 4.jpg"
            width="200px"
            style="width: auto;"
            alt=""
          >
        </b-link>
        <span>
          <h6 class="p-1">تم إرسال رمز التحقق الي الرقم {{ currentNumber }}</h6>
        </span>
        <b-card-title class="" style="direction: ltr;">
          <!-- في إنتظار التحقق... -->
          <!-- <div style="display: flex; flex-direction: row" class="p-1"> -->
          <otp-input
            ref="otpInput"
            input-classes="otp-input"
            class="justify-content-between p-1"
            separator="-"
            :num-inputs="5"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-complete="handleOnComplete"
          />
          <span>
            <h6 class="pt-2">
              {{ $t('resendOTPMessage') }}
              <b-link @click="resendOTP">
                {{ $t('resend') }}
              </b-link>
            </h6>
          </span>
        </b-card-title>
        <!-- <button @click="handleClearInput()">Clear Input</button> -->
        <!-- </div> -->
        <!-- <b-card-text class="mb-2 text-center">
          <div class="text-center">
            <b-spinner variant="primary" label="Text Centered" />
          </div>
        </b-card-text> -->
        <b-button
          variant="primary"
          @click="activate"
          :disabled="
            !activationData.activationKey ||
              activationData.activationKey.length < 5
          "
          block
        >
          {{ $t('activate') }}
        </b-button>
        <!-- <b-button variant="primary" block>
          {{ $t('reSend') }}
        </b-button> -->
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import {
  BCard,
  // BCardText,
  // BCardTitle,
  BButton,
  // BSpinner
} from 'bootstrap-vue';
import OtpInput from '@bachdgvn/vue-otp-input';

export default {
  components: {
    // BSpinner,
    BCard,
    // BCardText,
    // BCardTitle,
    BButton,
    OtpInput,
  },
  props: {
    mobileNum: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      activationData: {
        activationKey: '',
      },
      currentNumber: this.$route.params.mobileNum,
      // otpNumber: null,
    };
  },
  mounted() {
    // this.activationData.activationKey = decodeURIComponent(this.$route.query.key)
    // if (this.$route.query.resend) {
    //   this.resendOTP();
    // }
  },
  methods: {
    handleOnComplete(value) {
      this.activationData.activationKey = value;
    },
    resendOTP() {
      this.get({ url: 'Clients/resendOTP' })
        .then((res) => {
          this.doneAlert({ text: this.$t('activationDone') });
        })
        .catch(({ data }) => {
          this.doneAlert({ text: this.$t(data.detail), type: 'error' });
        });
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    activate() {
      this.create({ url: 'Clients/activate', data: this.activationData })
        .then((res) => {
          this.doneAlert({ text: this.$t('activationDone') });
          localStorage.setItem('@ACCESS_TOKEN', res.token);
          localStorage.setItem('@USER_PROFILE', JSON.stringify(res));
        })
        .then(() => {
          // if (localStorage.getItem('@ACCESS_TOKEN')) {
          //   this.$router.push({ name: 'home' })
          // } else
          if (
            localStorage.getItem('@ACCESS_TOKEN') && sessionStorage.getItem('INIT_OBJ')
          ) {
            this.$router.push({ name: 'calculations' });
          } else if (
            localStorage.getItem('@ACCESS_TOKEN') && !sessionStorage.getItem('INIT_OBJ')
          ) {
            this.$router.push({ name: 'home' });
          } else {
            this.$router.push({ name: 'login' });
          }
        })
        .catch(({ data }) => {
          this.doneAlert({ text: this.$t(data.detail), type: 'error' });
        });
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
